<template>
    <div class="my-4">
        <v-form v-model="isValid" ref="form" v-if="showForm">
            <v-card ref="cardCustomerInfo" id="card-customer-info" dark elevation="1" class="px-10"
                style="margin-right: 156px;">
                <v-card-title>
                    สร้างคำสั่งซื้อใหม่
                </v-card-title>
                <v-card-subtitle>
                    โปรดตรวจสอบความถูกต้องของข้อมูลก่อนกดยืนยัน
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <label class="d-block customer-information text-body-1 font-weight-bold">ข้อมูลลูกค้า</label>
                    <v-radio-group row v-model="form.customer.type">
                        <v-radio color="yellow darken-3" label="บุคคลธรรมดา" value="person"></v-radio>
                        <v-radio color="yellow darken-3" label="นิติบุคคล" value="corporation"></v-radio>
                    </v-radio-group>
                    <div class="d-block" v-if="'person' == form.customer.type">
                        <p class="text-caption mt-1">ชื่อลูกค้า</p>
                        <v-text-field color="yellow darken-3" required v-model="form.customer.name" dense
                            :rules="nameRules" placeholder="ex. ณัฐณีชา เบี้ยงศิริ">
                        </v-text-field>
                    </div>
                    <div class="d-block" v-if="'corporation' == form.customer.type">
                        <p class="text-caption mt-1">ชื่อบริษัท</p>
                        <v-text-field color="yellow darken-3" required v-model="form.customer.company" dense
                            :rules="requiredRules" placeholder="ใส่ชื่อบริษัท">
                        </v-text-field>
                        <p class="text-caption mt-1">ชื่อผู้ติดต่อ</p>
                        <v-text-field color="yellow darken-3" required v-model="form.customer.name" dense
                            :rules="nameRules" placeholder="ex. ณัฐณีชา เบี้ยงศิริ">
                        </v-text-field>
                    </div>


                    <v-row>
                        <v-col cols="4">
                            <p class="text-caption mt-1">เบอร์โทรศัพท์</p>
                            <v-text-field color="yellow darken-3" required v-model="form.customer.mobile" dense
                                :rules="mobileRules" placeholder="ex. 0394992346">
                            </v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <p class="text-caption mt-1">อีเมล</p>
                            <v-text-field color="yellow darken-3" required v-model="form.customer.email" dense
                                :rules="emailRules" placeholder="ex. movevai@gmail.com">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <p class="text-caption mt-1">หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี</p>
                    <v-text-field  color="yellow darken-3" required v-model="form.customer.idTax" dense maxlength="13"
                        :rules="taxRules">
                    </v-text-field>
                    <p class="text-caption mt-2">ที่อยู่</p>
                    <v-textarea color="yellow darken-3" filled outlined counter="255" v-model="form.customer.address"
                        rows="3" :rules="addressRules"></v-textarea>
                </v-card-text>
            </v-card>
            <v-card id="card-product-channel" class="mt-5 px-10" dark elevation="1" style="margin-right: 156px;">
                <v-card-text>
                    <label
                        class="d-block customer-information text-body-1 font-weight-bold">เลือกช่องทางการจัดส่ง</label>
                    <v-row>
                        <v-col>
                            <v-radio-group row v-model="form.channel">
                                <v-radio v-for="option of permissionOptionsWithCondition" :key="option.value"
                                    :disabled="-1 == currentPermission.indexOf(option.value)" color="yellow darken-3"
                                    :label="option.label" :value="option.value"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Begin Ordering -->
            <div ref="cardProductInfo">
                <v-card v-for="(product, n) of form.products" :key="product.id" id="card-product-info"
                    class="mt-5 px-10" dark elevation="1" style="margin-right: 156px;">
                    <v-card-text>
                        <label class="d-block customer-information text-body-1 font-weight-bold">ข้อมูลสินค้า {{ n + 1
                            }} </label>
                        <v-radio-group row v-model="product.type">
                            <v-radio v-for="c of productTypeOptions" :key="c.value" color="yellow darken-3"
                                :label="c.name" :value="c.value">
                            </v-radio>
                        </v-radio-group>
                        <div v-if="getSubProductType(product).length > 0">
                            <p class="text-caption mt-1">ประเภทสินค้า</p>
                            <v-select style="width: 30%" :items="getSubProductType(product)" item-text="name"
                                item-value="value" filled v-model="product.subType" dense></v-select>
                        </div>
                        <p class="text-caption mt-3">รายละเอียดสินค้า</p>
                        <v-text-field class="mt-n5" color="yellow darken-3" required v-model="product.details"
                            placeholder="ตัวอย่าง เช่น ลายสินค้า หรืออัญมณีชนิดไหน ">
                        </v-text-field>
                        <v-row>
                            <v-col>
                                <div class="d-flex">
                                    <p class="text-caption mt-3" style="width: 65%">น้ำหนักสินค้า</p>
                                    <p v-if="product.type == '4'" class="text-caption mt-3" style="width: 35%">หน่วย</p>
                                </div>

                                <div class="d-flex mt-n5">
                                    <v-text-field style="width: 65%" color="yellow darken-3" required
                                        v-model="product.weight.value" :rules="numberAndDecimalRules" placeholder="">
                                    </v-text-field>
                                    <v-text-field v-if="product.type == '4'" style="width: 35%" color="yellow darken-3"
                                        required v-model="product.weight.unit"
                                        :rules="product.type == '4' ? requiredRules : []" placeholder="กรอกหน่วย">
                                    </v-text-field>
                                    <v-select v-else style="width: 35%" :items="getWeightUnit(product)" item-text="name"
                                        item-value="value" color="yellow darken-3" v-model="product.weight.unit">
                                    </v-select>
                                </div>
                            </v-col>
                            <v-col v-if="product.type != '1'">
                                <p class="text-caption mt-3">จำนวนสินค้า</p>
                                <div class="d-flex mt-n5">
                                    <v-text-field style="width: 70%" color="yellow darken-3" required
                                        v-model="product.quantity.value" :rules="numberRules" placeholder="">
                                    </v-text-field>
                                    <v-select style="width: 30%;" :items="getQuantityUnit(product)" item-text="name"
                                        item-value="value" color="yellow darken-3" v-model="product.quantity.unit">
                                    </v-select>
                                </div>
                            </v-col>
                            <v-col>
                                <p class="text-caption mt-3">มูลค่าสินค้า</p>
                                <div class="d-flex mt-n5">
                                    <v-text-field style="width: 70%" color="yellow darken-3" required
                                        v-model="product.price" :rules="numberWithCommasRules" placeholder="">
                                        <template v-slot:append>
                                            <label class="grey--text">| บาท</label>
                                        </template>
                                    </v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                        <p class="text-caption mt-3">หมายเหตุ</p>
                        <v-text-field class="mt-n5" color="yellow darken-3" required v-model="product.note"
                            placeholder="">
                        </v-text-field>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            v-if="product.id == form.products[form.products.length - 1].id && form.products.length > 1"
                            @click="removeListProducts" class="mr-6 my-6" outlined color="grey">
                            <v-icon>
                                mdi-minus
                            </v-icon>
                            ลบรายการ
                        </v-btn>
                        <v-btn v-if="product.id == form.products[form.products.length - 1].id" @click="addListProducts"
                            class="mr-6 my-6" outlined color="yellow darken-3">
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            เพิ่มรายการ
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <!-- End Ordering -->

            <!-- Direction -->
            <v-card id="card-product-ordering" ref="cardProductOrdering" class="mt-5 px-10" dark elevation="1"
                style="margin-right: 156px;">
                <v-card-text>
                    <label
                        class="d-block customer-information text-body-1 font-weight-bold mb-6">ข้อมูลการจัดส่ง</label>
                    <p class="text-caption mt-1">น้ำหนักรวมสินค้า</p>
                    <v-row class="px-3">
                        <v-text-field color="yellow darken-3" required v-model="form.ordering.weight.value"
                            :rules="numberAndDecimalRules" placeholder="">
                        </v-text-field>
                        <div style="width: 100px">
                            <v-select class="" :items="[{ name:'กิโลกรัม', value: 'kg'}, { name: 'กรัม', value: 'g'}]"
                                item-text="name" item-value="value" color="yellow darken-3"
                                v-model="form.ordering.weight.unit">
                            </v-select>
                        </div>
                    </v-row>
                    <!-- <v-row>
                <v-col>
                    <p class="text-caption mt-1">ขนาดพัสดุ (กว้าง)</p>
                    <v-text-field 
                        color="yellow darken-3"
                        required 
                        v-model="form.ordering.width" 
                        :rules="numberRules"
                        placeholder="">
                        <template v-slot:append>
                            <label class="grey--text">| cm</label>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col>
                    <p class="text-caption mt-1">ขนาดพัสดุ (ยาว)</p>
                    <v-text-field 
                        color="yellow darken-3"
                        required 
                        v-model="form.ordering.length" 
                        :rules="numberRules"
                        placeholder="">
                        <template v-slot:append>
                            <label class="grey--text">| cm</label>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col>
                    <p class="text-caption mt-1">ขนาดพัสดุ (สูง)</p>
                    <v-text-field 
                        color="yellow darken-3"
                        required 
                        v-model="form.ordering.height" 
                        :rules="numberRules"
                        placeholder="">
                        <template v-slot:append>
                            <label class="grey--text">| cm</label>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row> -->
                    <label
                        class="d-block customer-information text-body-1 font-weight-bold my-6">รูปแบบการจัดส่ง</label>
                    <v-radio-group class="mb-5" row v-model="form.orderType">
                        <v-radio color="yellow darken-3" label="เที่ยวเดียว" value="1"></v-radio>
                        <v-radio v-if="form.channel != '3'" color="yellow darken-3" label="ไป - กลับ" value="2">
                        </v-radio>
                    </v-radio-group>
                    <div v-if="form.orderType == '1'">
                        <p class="text-caption mt-1">สถานที่ในการจัดส่ง</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required readonly
                                    v-model="form.ordering.address1Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '1' ? mapRulesV2(form.ordering.address1Start.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Start, form.channel)"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn icon class="ml-n3" @click="swapAddress1">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required
                                    v-model="form.ordering.address1Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '1' ? mapRulesV2(form.ordering.address1Destination.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Destination, form.channel)"
                                    placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else>
                        <p class="text-caption mt-1">สถานที่ในการจัดส่ง (ขาไป)</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required
                                    v-model="form.ordering.address1Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address1Start.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Start, form.channel)"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn icon class="ml-n3" @click="swapAddress1">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required
                                    v-model="form.ordering.address1Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address1Destination.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address1Destination, form.channel)"
                                    placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                        <v-btn outlined color="yellow darken-3" @click="swapAddress">
                            สลับ
                            <v-icon>
                                mdi-swap-vertical
                            </v-icon>
                        </v-btn>
                        <p class="text-caption mt-5">สถานที่ในการจัดส่ง (ขากลับ)</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required
                                    v-model="form.ordering.address2Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address2Start.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address2Start, form.channel)"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-btn icon class="ml-n3" @click="swapAddress2">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required
                                    v-model="form.ordering.address2Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps"
                                    :rules="form.orderType == '2' ? mapRulesV2(form.ordering.address2Destination.address1, form.channel) : []"
                                    @click="$refs.dialogAddressForm.show(form.ordering.address2Destination, form.channel)"
                                    placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider class="my-5" />
                    <v-row class="mb-5">
                        <v-col>
                            <label
                                class="d-block customer-information text-body-1 font-weight-bold my-6">ตัวเลือกการชำระเงิน</label>
                            <v-radio-group class="mb-5" row v-model="form.paymentType">
                                <v-radio v-if="form.channel != '3'" color="yellow darken-3" label="เก็บเงินปลายทาง"
                                    value="1"></v-radio>
                                <v-radio color="yellow darken-3" label="โอนเงินผ่านธนาคาร หรือ Mobile Banking"
                                    value="2"></v-radio>
                                <v-radio v-if="form.customer.type == 'corporation'" color="yellow darken-3"
                                    label="เครดิต" value="3"></v-radio>
                            </v-radio-group>
                            <div class="d-flex mb-5" v-if="form.paymentType == '2'">
                                <v-btn color="primary" outlined dark @click="$refs.uploader.click()">
                                    <v-icon>mdi-file-plus</v-icon> เพิ่มไฟล์
                                </v-btn>
                                <v-chip v-if="form.paymentFileDownload != null" class="ml-4 ma-1" close color="gray"
                                    outlined link @click="goToDownloadPaymentFile" @click:close="removeUploadFile">
                                    {{ form.paymentFileDownload.name }}
                                </v-chip>
                                <v-icon v-if="form.paymentFileDownload != null" color="yellow darken-3"> mdi-check
                                </v-icon>
                                <v-chip v-if="paymentFileName" class="ml-4 ma-1" close color="gray" outlined
                                    @click:close="removeUploadFile">
                                    {{ paymentFileName }}
                                </v-chip>
                                <input ref="uploader" class="d-none" type="file" @change="onFileChanged">
                            </div>
                            <p class="text-caption mt-n3 mb-3">หมายเหตุการชำระเงิน * </p>
                            <v-text-field class="mt-n3" color="yellow darken-3" required
                                :rules="form.paymentType == '3' || form.paymentType == '1' ? requiredRules : []"
                                v-model="form.paymentNote" placeholder="หมายเหตุ. การชำระเงิน">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-container>
                <div class="d-flex my-8" style="margin-right: 156px;">
                    <v-spacer />
                    <v-btn class="mx-2" outlined color="yellow darken-3" @click="$refs.confirm.show()">
                        ยกเลิกรายการ
                    </v-btn>
                    <v-btn class="mx-2" outlined color="yellow darken-3" @click="clearFormData">
                        เคลียร์รายการ
                    </v-btn>
                    <v-btn class="mx-2" color="yellow darken-3" @click="saveDraft">
                        <span class="black--text">บันทึกแบบร่าง</span>
                    </v-btn>
                    <v-btn class="mx-2" color="yellow darken-3" @click="createJob">
                        <span class="black--text">สร้างรายการสั่งซื้อ</span>
                    </v-btn>
                </div>
            </v-container>
            <dialog-address-form @confirm="dialogAddressConfirm" ref="dialogAddressForm" />
        </v-form>

        <!-- Right Menu -->
        <right-drawer :rMenu="rMenu" />

        <!-- snackbar save -->
        <v-snackbar v-model="snackbarSuccess" :multi-line="true">
            <v-icon color="yellow darken-3">
                mdi-content-save-check
            </v-icon>
            บันทึกแบบร่างเรียบร้อยแล้ว

            <template v-slot:action="{ attrs }">
                <v-btn color="yellow darken-3" text v-bind="attrs" @click="snackbarSuccess = false">
                    ปิด
                </v-btn>
            </template>
        </v-snackbar>
        <alert-error :text="errorMessage" ref="alertError"></alert-error>
        <dialog-confirm :title="'ยืนยันออกจากหน้าสร้างรายการสั่งซื้อ'"
            :description="'โปรดตรวจสอบทุกครั้งว่าได้บันทึกข้อมูลแล้วหรือยัง เพื่อป้องกันไม่ไห้ข้อมูลหายไปหลังจากกดออก'"
            @confirm="cancel" ref="confirm" />
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mockProduct, channelOptions, getDraftApi, saveDraftApi, downloadPaymentFile } from '../models/job';
import { getProfile } from '../models/user';
import DialogAddressForm from '../components/DialogAddressForm.vue';
import { nameRules, mobileRules, emailRules, addressRules, numberRules, mapRules, mapRulesV2, requiredRules, numberWithCommasRules, numberAndDecimalRules, taxRules } from '../utils/validation-rule';
import RightDrawer from '../components/RightDrawer.vue';
import AlertError from '../components/AlertError.vue';
import DialogConfirm from '../components/DialogConfirm.vue';
import Text from '../utils/text-mapping';

export default {
    components: { DialogAddressForm, RightDrawer, AlertError, DialogConfirm },
    prop: {
        overviewForm: { type: Object, default: null }
    },
    data: () => ({
        productJa: '',
        paymentFileName: '',
        isSelecting: false,
        snackbarSuccess: false,
        productTypeOptions: [],
        subProductTypeOptions: [],
        channelOptions: [],
        initForm: null,
        form: {
            code: '',
            channel: '1',
            customer: {
                type: 'person',
                name: '',
                mobile: '',
                email: '',
                address: ''
            },
            orderType: '1',
            products: [
                mockProduct()
            ],
            ordering: {
                weight: {
                    value: null,
                    unit: 'kg'
                },
                address1Start: {
                    id: 'address1Start',
                    address1: '',
                    address2: '',
                    location: null,
                    text: ''
                },
                address1Destination: {
                    id: 'address1Destination',
                    address1: '',
                    address2: '',
                    location: null,
                    text: ''
                },
                address2Start: {
                    id: 'address2Start',
                    address1: '',
                    address2: '',
                    location: null,
                    text: ''
                },
                address2Destination: {
                    id: 'address2Destination',
                    address1: '',
                    address2: '',
                    location: null,
                    text: ''
                },
                length: null,
                width: null,
                height: null,
                shippingFee: 0
            },
            paymentType: '',
            paymentNote: '',
            paymentFile: null,
            paymentFileDownload: null,
            isDeletePaymentFile: false
        },
        nameRules: nameRules,
        mobileRules: mobileRules,
        emailRules: emailRules,
        addressRules: addressRules,
        numberRules: numberRules,
        mapRules: mapRules,
        mapRulesV2,
        requiredRules: requiredRules,
        taxRules,
        numberWithCommasRules,
        numberAndDecimalRules,
        isValid: false,
        keyProductIndex: 0,
        rMenu: [
            { id: 1, name: 'ข้อมูลลูกค้า', isActive: true, go: 85 },
            { id: 2, name: 'ข้อมูลสินค้า', isActive: false, go: 792 },
            { id: 3, name: 'การจัดส่ง และการชำระเงิน', isActive: false, go: 1321 }
        ],
        errorMessage: '',
        currentUser: null,
        showForm: false,
        permissionOptions: Text.permissionOptions()
    }),
    methods: {
        addListProducts(){
            this.form.products.push( mockProduct() );
            this.refreshRMenuPosition();
        },
        removeListProducts(){
            this.form.products.pop();
            this.refreshRMenuPosition();
        },
        go(ref){
            this.$vuetify.goTo(this.$refs[ref]);
        },
        getSubProductType(product){
            return product ? this.channelOptions[this.form.channel].subProductType[product.type] : []; 
        },
        getWeightUnit(product){
            return product ? this.channelOptions[this.form.channel].weightUnit[product.type] : []; 
        },
        getQuantityUnit(product){
            return product ? this.channelOptions[this.form.channel].quantityUnit[product.type] : []; 
        },
        dialogAddressConfirm(data){
            this.form.ordering[data.id] = data;
        },
        setAddressId() {
            this.form.ordering.address1Start.id = 'address1Start';
            this.form.ordering.address1Destination.id = 'address1Destination';
            this.form.ordering.address2Start.id = 'address2Start';
            this.form.ordering.address2Destination.id = 'address2Destination';
        },
        swapAddress1(){
            const temp = this.form.ordering.address1Start;
            this.form.ordering.address1Start = this.form.ordering.address1Destination;
            this.form.ordering.address1Destination = temp;
            this.setAddressId();
        },
        swapAddress2(){
            const temp = this.form.ordering.address2Start;
            this.form.ordering.address2Start = this.form.ordering.address2Destination;
            this.form.ordering.address2Destination = temp;
            this.setAddressId();
        },
        swapAddress(){
            const temp1 = this.form.ordering.address1Start;
            const temp2 = this.form.ordering.address1Destination;

            this.form.ordering.address1Start = this.form.ordering.address2Start;
            this.form.ordering.address1Destination = this.form.ordering.address2Destination;
            this.form.ordering.address2Start = temp1;
            this.form.ordering.address2Destination = temp2;
            this.setAddressId();
        },
        async saveDraft(){
            let loader = this.$loading.show();
            this.errorMessage = '';
            try{
                await saveDraftApi(this.form);
                const data = await getDraftApi();
                if(data){
                    this.form = data;
                }
                this.paymentFileName = '';
                this.snackbarSuccess = true;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        createJob(){
            this.fileErrorMessage = '';
            this.errorMessage = '';
            this.$refs.form.validate();

            if(!this.isValid){
                this.errorMessage = 'โปรดกรอกข้อมูลให้ครบ';
                this.$refs.alertError.show();
                return '';
            }
            this.$emit('overview', this.form);
        },
        cancel(){
            this.$router.go(-1);
        },
        refreshRMenuPosition(){
            setTimeout(() => {
                const cardCustomerInfo = this.$refs['cardCustomerInfo'].$el.offsetTop;
                const cardProductInfo = this.$refs['cardProductInfo'].firstChild.offsetTop;
                const cardProductOrdering = this.$refs['cardProductOrdering'].$el.offsetTop;
                this.rMenu[0].go = cardCustomerInfo;
                this.rMenu[1].go = cardProductInfo;
                this.rMenu[2].go = cardProductOrdering;
            }, 500);
        },
        async goToDownloadPaymentFile(){
            await downloadPaymentFile(this.form.paymentFileDownload);
        },
        onFileChanged(e){
            this.form.paymentFileDownload = null;
            this.form.paymentFile = e.target.files[0];
            this.paymentFileName = this.form.paymentFile.name;
            this.form.isDeletePaymentFile = false;
        },
        removeUploadFile(){
            this.form.paymentFile = null;
            this.form.paymentFileDownload = null;
            this.form.isDeletePaymentFile = true;
            this.paymentFileName = '';
        },
        clearFormData(){
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.initForm);
        },
        autoFillAddress1Start(){
            // Auto fill when channel is move sameday and move express
            if('1' == this.form.channel || '3' == this.form.channel){
                this.form.ordering.address1Start.address1 = 'แขวงสีลม, เขตบางรัก, จังหวัดกรุงเทพมหานคร, 10500';
                this.form.ordering.address1Start.address2 = 'บริษัท มูฟไว จากัด 32/7-8 ถ.สุรศักดิ์';
                this.form.ordering.address1Start.text = `${this.form.ordering.address1Start.address2} ${this.form.ordering.address1Start.address1}`;
                this.form.ordering.address1Start.location = { lat: 13.7211614, lng: 100.5191163 };
            }else{
                this.form.ordering.address1Start.address1 = '';
                this.form.ordering.address1Start.address2 = '';
                this.form.ordering.address1Start.text = '';
                this.form.ordering.address1Start.location = null;
            }
        }
    },
    computed: {
        currentRole(){
            return this.currentUser ? this.currentUser.role : '';
        },
        currentPermission(){
            return this.currentUser ? this.currentUser.permissions : [];
        },
        permissionOptionsWithCondition(){
            // V.1 filter move transit out when user role is corp.
            // if('person' == this.form.customer.type){
            //     return this.permissionOptions.filter(p => p.value != '2');
            // }else{
            //     return this.permissionOptions;
            // }

            // v.2 allow all
            return this.permissionOptions;
        }
    },
    async mounted(){
        this.$vuetify.theme.dark = true;
        this.refreshRMenuPosition();
        this.initForm = Object.assign({}, this.form);
        this.autoFillAddress1Start();
        // get draft data
        let loader = this.$loading.show();
        this.showForm = true;
        try{
            const data = await getDraftApi();
            const userResponse = await getProfile();
            this.currentUser = userResponse.data.user;
            if(0 == this.currentUser.permissions.length){
                this.showForm = false;
                throw Error('ไม่สามารถสร้างคำสั่งซื้อใดๆได้เนื่องจาก Staff User ไม่มีสิทธิ์การเข้าถึง');
            }
            // set defalut channel
            this.form.channel = this.currentPermission[0];
            if(data){
                this.form = data;
            }
        }catch(error){
            this.errorMessage = error.response?.data || error.message;
            this.$refs.alertError.show();
        }finally{
            loader.hide();
        }
    },
    created(){
        this.form.products.forEach(p => {
            p.key = new Date().getTime();
        });
        this.channelOptions = channelOptions;
        this.productTypeOptions = this.channelOptions[this.form.channel].productType;
    },
    watch: {
        'form.channel'(value){
            this.productTypeOptions = this.channelOptions[value].productType;
            this.form.products.forEach(p => { // fix. when choose product type with no render will error
                if('3' == this.form.channel && '1' == p.type){
                    p.type = '2';
                }
            });
            // protect null value
            if('3' == this.form.channel){
                this.form.orderType = this.form.orderType || '1';
                this.form.paymentType = this.form.paymentType || '1';
            }
            
            this.autoFillAddress1Start();
        },
        'form.products': {
            // fix. value in scope
            handler(){
                for(let i = 0; i < this.form.products.length ; i++){
                    const type = this.form.products[i].type;
                    const weightUnit = this.form.products[i].weight.unit;
                    const quantityUnit = this.form.products[i].quantity.unit;
                    switch(type) {
                        case '1':
                            if('b' != weightUnit){
                                this.form.products[i].weight.unit = 'b';
                            }
                            break;
                        case '2':
                            if(-1 == ['b', 'g', 'kg'].indexOf(weightUnit)){
                                this.form.products[i].weight.unit = 'b';
                            }
                            if('1' != quantityUnit){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '3':
                            if(-1 == ['b', 'g', 'kg'].indexOf(weightUnit)){
                                this.form.products[i].weight.unit = 'b';
                            }
                            if(-1 == ['1', '2', '3', '4'].indexOf(quantityUnit)){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '4':
                            if(-1 == ['1', '2', '3', '4'].indexOf(quantityUnit)){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '5':
                            if('g' != weightUnit){
                                this.form.products[i].weight.unit = 'g';
                            }
                            if('1' != quantityUnit){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        case '6':
                            if('g' != weightUnit){
                                this.form.products[i].weight.unit = 'g';
                            }
                            if('1' != quantityUnit){
                                this.form.products[i].quantity.unit = '1';
                            }
                            break;
                        default:
                            break;
                    }

                    // replace price with comma.
                    if(this.form.products[i].price){
                        const price = this.form.products[i].price.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        // this.form.products[i].price = price;
                        this.$nextTick(() => this.form.products[i].price = price);
                    }
                    
                }
            },
            deep: true
        }
    }
};
</script>

<style>
    .v-select__selections{
        line-height: 25px !important;
        margin-top: -7px;
    }
</style>
<template>
    <v-navigation-drawer
        v-model="drawer"
        right
        dark
        fixed
        permanent
        hide-overlay
        clipped
        floating
        style=" top: 140px; background-color: #191B20"
    >
        <v-list tag="ul">
            <v-list-item 
                v-for="item of rMenu"
                :key="item.id"
                tag="li" 
                link 
                :class="[item.isActive ? 'right-menu-active' : 'right-menu']"
                @click="rMenuClick(item)"
                class="li-right pl-3 text-body-2 py-1 font-weight-regular">
                <a 
                    :class="[item.isActive ? 'a-right-menu-active' : 'a-right-menu']" 
                    class="text-decoration-none">
                    {{ item.name }}
                </a>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
        rMenu: {
            type: Array,
            default: () => [
                { id: 1, name: 'ข้อมูลลูกค้า', isActive: true, go: 85 },
                { id: 2, name: 'ข้อมูลสินค้า', isActive: false, go: 792 },
                { id: 3, name: 'การจัดส่ง และการชำระเงิน', isActive: false, go: 1321 }
            ]
        }
    },
    data: () => ({
        drawer: true,
        offset: 64,
        active: 0,
        timer: null
    }),
    methods: {
        rMenuClick(e){
            this.$vuetify.goTo(e.go + this.offset);
            this.rMenu.forEach(r => {
                r.isActive = e.id == r.id;
            });
        }
    },
    mounted(){
        document.addEventListener('scroll', () => {
            if(this.timer !== null) {
                clearTimeout(this.timer);        
            }
            this.timer = setTimeout(() => {
                const position = window.scrollY;
                for(let i = 0; i < this.rMenu.length; i++){
                    if(0 == i){
                        if(position < this.rMenu[1].go){
                            this.active = 0; break;
                        }
                    }else if(i == this.rMenu.length - 1){
                        if(position >= this.rMenu[i].go){
                            this.active = i;
                        }
                    }else{
                        if(position >= this.rMenu[i].go && position < this.rMenu[i + 1].go){
                            this.active = i;
                        }
                    }
                }
                this.rMenu.forEach(r => {
                    r.isActive = this.rMenu[this.active].id == r.id;
                });
            }, 500);

        });
    }
};
</script>

<style scoped>
    .li-right{
        min-height: 28px;
    }
    .right-menu-active{
        border-left: 0.5px solid #efbc43;
    }
    .right-menu{
        border-left: 0.5px solid #9E9E9E;
    }
    .a-right-menu-active{
        color: #efbc43;
    }
    .a-right-menu{
        color: #9E9E9E;
    }
</style>


<template>
    <v-container>
        <v-breadcrumbs dark :items="breadcrumbs">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <job-overview v-if="isShowOverview" @back="showForm" :form="form"></job-overview>
        <job-form v-else  ref="jobForm" @overview="showOverview"/>
    </v-container>
</template>

<script>
import JobForm from '../../components/JobForm.vue';
import JobOverview from '../../components/JobOverview.vue';

export default {
    data: () => ({
        breadcrumbs: [
        {
          text: 'หน้าหลัก',
          disabled: false,
          href: '/'
        },
        {
          text: 'สร้างคำสั่งซื้อใหม่',
          disabled: true,
          href: '/jobs/create'
        }
      ],
      isShowOverview: false,
      form: null
    }),
    methods: {
        showOverview(form){
            this.isShowOverview = true;
            this.form = form;
        },
        showForm(){
            this.isShowOverview = false;
        }
    },
    components: {
        JobForm,
        JobOverview
    }
};
</script>
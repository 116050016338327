<template>
    <v-container>
        <div class="mt-3" v-if="form == null">
            <v-alert type="danger"> Not found data</v-alert>
        </div>
        <div class="mt-3" v-else>
            <v-card class="px-10" dark>
                <v-card-title>
                    ยืนยันข้อมูลคำสั่งซื้อ
                </v-card-title>
                <v-card-subtitle>
                    โปรดตรวจสอบความถูกต้องของข้อมูลก่อนกดยืนยัน
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <label class="d-block customer-information text-body-1 font-weight-bold">ข้อมูลลูกค้า</label>
                    <v-row class="mt-5">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <div class="d-flex" v-if="'corporation' == form.customer.type">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ชื่อบริษัท</label>
                                        <label class="text-body1">{{ form.customer.company }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ชื่อลูกค้า</label>
                                        <label class="text-body1">{{ form.customer.name }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ประเภทลูกค้า</label>
                                        <label class="text-body1">{{ form.customer.type == 'person' ? 'บุคคลธรรมดา' :
                                            'นิติบุคคล' }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">เบอร์โทร</label>
                                        <label class="text-body1">{{ mobileText }} </label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ที่อยู่</label>
                                        <label class="text-body1" style="width: 70%">{{ form.customer.address }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text" style="width: 30%">ID /
                                            Tax</label>
                                        <label class="text-body1">
                                            {{ idTaxText }}</label>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <div class="d-block">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ยอดรวม</label>
                                        <label class="text-h6 yellow--text text--darken-3">{{ totalPrice }} บาท</label>
                                    </div>
                                    <div class="d-block">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ตัวเลือกการจัดส่ง</label>
                                        <label class="text-body1">{{ channelText }}</label>
                                    </div>
                                    <div class="d-block">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ช่องทางการชำระเงิน</label>
                                        <label class="text-body1">{{ paymentText }}</label>
                                    </div>
                                    <div class="d-block" v-if="form.paymentType == '2'">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">การชำระเงิน</label>
                                        <label v-if="form.paymentType == '1' || form.paymentType == '3'"
                                            class="text-body1">ยังไม่ชำระเงิน</label>
                                        <label
                                            v-if="form.paymentType == '2' && form.paymentFile == null && form.paymentFileDownload == null"
                                            class="text-body1">ยังไม่ชำระเงิน</label>
                                        <label
                                            v-if="form.paymentType == '2' && (form.paymentFile != null || form.paymentFileDownload != null)"
                                            class="text-body1">ชำระเงินแล้ว</label>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card dark class="px-10 mt-5">
                <v-card-text>
                    <label class="d-block customer-information text-body-1 font-weight-bold">ข้อมูลสินค้า</label>
                    <v-data-table :headers="header" :items="form.products">
                        <template v-slot:[`item.name`]="{ item }">
                            <div>
                                <v-img class="d-inline-flex" lazy-src="https://picsum.photos/id/11/10/6"
                                    src="@/assets/images/logo1.svg" max-height="84" max-width="84">
                                </v-img>
                                <div class="ml-4 d-inline-block pa-2">
                                    <label class="d-block font-weight-bold">{{ item.details ? item.details :
                                        transformProductType(item.type) }}</label>
                                    <label class="d-block grey--text">ขนาด {{ item.weight.value }} {{
                                        transformWeightValueToUnit(item.weight.unit) }}</label>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.code`]="{}">
                            <label> </label>
                        </template>
                        <template v-slot:[`item.quantity`]="{item}">
                            <label> {{ item.quantity ? item.quantity.value : '-' }} </label>
                        </template>
                        <template v-slot:[`item.price`]="{item}">
                            <label class="font-weight-bold yellow--text text--darken-3"> {{
                                numberToLocalText(item.price) }} </label>
                        </template>
                    </v-data-table>

                    <div class="mt-5">
                        <label class="font-weight-bold yellow--text text--darken-3">สรุปยอดรายการ</label>
                        <div class="d-block">
                            <label class="text-body1 d-inline-block grey--text" style="width: 25%">น้ำหนักรวม</label>
                            <label class="text-body1 white--text">{{ form.ordering.weight.value }} {{
                                orderingWeightUnitText }}</label>
                        </div>
                        <!-- <div class="d-block">
                            <label class="text-body1 d-inline-block grey--text" style="width: 25%">ขนาดพัสดุ</label>
                            <label class="text-body1 white--text">{{ form.ordering.width }} x {{ form.ordering.length }} x {{ form.ordering.height }} cm.</label>
                        </div> -->
                        <div class="d-block">
                            <label class="text-body1 d-inline-block grey--text" style="width: 25%">ค่าจัดส่ง</label>
                            <div class="d-inline-block pa-0 mt-3" style="width: 120px;">
                                <v-text-field maxlength="5" v-model="form.ordering.shippingFee" outlined :rules="[ v => {
                                                const pattern = /^\d+(\.\d+)?$/;
                                                return pattern.test(v) || 'เฉพาะตัวเลขเท่านั้น';
                                                }
                                            ]" dense suffix="บาท">
                                </v-text-field>
                            </div>

                        </div>
                        <!-- <div class="d-block mt-n4">
                            <label class="text-body1 d-inline-block grey--text" style="width: 25%">ยอดรวม</label>
                            <label class="text-h6 white--text">{{ sumProductsPrice }} บาท</label>
                        </div> -->
                    </div>
                </v-card-text>
            </v-card>

            <v-card dark class="px-10 mt-5">
                <v-card-text>
                    <label class="d-block customer-information text-body-1 font-weight-bold">ข้อมูลการจัดส่ง</label>
                    <v-radio-group class="mb-5" row disabled v-model="form.orderType">
                        <v-radio color="yellow darken-3" label="เที่ยวเดียว" value="1"></v-radio>
                        <v-radio v-if="form.channel != '3'" color="yellow darken-3" label="ไป - กลับ" value="2">
                        </v-radio>
                    </v-radio-group>

                    <!-- Address Location -->
                    <div v-if="form.orderType == '1'">
                        <p class="text-caption mt-1">สถานที่ในการจัดส่ง</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" disabled required readonly
                                    v-model="form.ordering.address1Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" disabled required
                                    v-model="form.ordering.address1Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps" placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-else>
                        <p class="text-caption mt-1">สถานที่ในการจัดส่ง (ขาไป)</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required disabled
                                    v-model="form.ordering.address1Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required disabled
                                    v-model="form.ordering.address1Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps" placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <p class="text-caption mt-5">สถานที่ในการจัดส่ง (ขากลับ)</p>
                        <v-row>
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required disabled
                                    v-model="form.ordering.address2Start.text" prepend-inner-icon="mdi-crosshairs-gps"
                                    placeholder="เพิ่มที่อยู่ต้นทาง">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="11">
                                <v-text-field class="mt-n3" color="yellow darken-3" required disabled
                                    v-model="form.ordering.address2Destination.text"
                                    prepend-inner-icon="mdi-crosshairs-gps" placeholder="เพิ่มที่อยู่ปลายทาง">
                                </v-text-field>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>

            <!-- button area -->
            <v-container>
                <div class="d-flex my-8">
                    <v-spacer />
                    <v-btn class="mx-2" outlined color="yellow darken-3" @click="$emit('back')">
                        ย้อนกลับ
                    </v-btn>
                    <v-btn class="mx-2" color="yellow darken-3" @click="createJob">
                        <span class="black--text">สร้างรายการสั่งซื้อ</span>
                    </v-btn>
                </div>
            </v-container>
        </div>
        <alert-error :text="errorMessage" ref="alertError"></alert-error>
        <alert-success :text="successMessage" :redirectTo="{ name: 'Jobs'}" ref="alertSuccess"></alert-success>

        <!-- snackbar save -->
        <v-snackbar v-model="snackbarSuccess" :multi-line="true">
            <v-icon color="yellow darken-3">
                mdi-content-save-check
            </v-icon>
            บันทึกแบบร่างเรียบร้อยแล้ว

            <template v-slot:action="{ attrs }">
                <v-btn color="yellow darken-3" text v-bind="attrs" @click="snackbarSuccess = false">
                    ปิด
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */

import { createJobApi, getChannelName, saveDraftApi } from '../models/job';
import { getPaymentText } from '../models/payment';
import AlertSuccess from '../components/AlertSuccess.vue';
import AlertError from '../components/AlertError.vue';
import axios from 'axios';
import Text from '../utils/text-mapping';

export default {
    props: {
        form: Object,
        default: null
    },
    data: () => ({
        header: [
            {
                text: 'รายการสินค้า',
                sortable: true,
                value: 'name'
            },
            {
                text: 'รหัสสินค้า',
                sortable: true,
                value: 'code'
            },
            {
                text: 'จำนวณ',
                sortable: true,
                value: 'quantity'
            },
            {
                text: 'มูลค่าสินค้า',
                sortable: true,
                value: 'price'
            }
        ],
        products: [
        ],
        successMessage: '',
        errorMessage: '',
        snackbarSuccess: false
    }),
    methods: {
        async saveDraft(){
            let loader = this.$loading.show();
            this.errorMessage = '';
            try{
                await saveDraftApi(this.form);
                this.snackbarSuccess = true;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async createJob(){
            let loader = this.$loading.show();
            this.errorMessage = '';
            try{
                await createJobApi(this.form);
                this.successMessage = 'สร้าง Job สำเร็จแล้ว';
                this.$refs.alertSuccess.show();
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        transformWeightValueToUnit(value){
            if('g' == value){
                return 'กรัม';
            }else if('kb' == value){
                return 'กิโลกรัม';
            }else if('b' == value){
                return 'บาท';
            }else{
                return value;
            }
        },
        numberToLocalText(number){
            const value1 = parseInt(number.replace(/,/g, ''), 10);
            return Number(value1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        transformProductType(type){
            return Text.productTypeToText(type);
        }
    },
    computed: {
        idTaxText () {
            const idTax = this.form.customer.idTax;
            if (idTax && 13 == idTax.length) {
                return `${idTax[0]}-${idTax[1]}${idTax[2]}${idTax[3]}${idTax[4]}-${idTax[5]}${idTax[6]}${idTax[7]}${idTax[8]}${idTax[9]}-${idTax[10]}${idTax[11]}-${idTax[12]}`;
            } else {
                return idTax;
            }
        },
        totalPrice(){
            let totalPrice = 0;
            this.form.products.forEach(p => {
                const value1 = parseInt(p.price.replace(/,/g, ''), 10);
                totalPrice += Number(value1);
            });
            return totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        channelText(){
            return getChannelName(this.form.channel);
        },
        paymentText(){
            return getPaymentText(this.form.paymentType);
        },
        orderingWeightUnitText(){
            const unit = this.form.ordering.weight?.unit || '';
            if('g' == unit){
                return 'กรัม';
            }else if('kg' == unit){
                return 'กิโลกรัม';
            }else {
                return '';
            }
        },
        shippingFeeText(){
            return this.form.ordering.shippingFee.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        sumProductsPrice(){
            const products = this.form.products;
            let price = 0;
            products.forEach(p => {
                const value1 = parseInt(p.price.replace(/,/g, ''), 10);
                price += Number(value1);
            });
            price += Number(this.form.ordering.shippingFee);
            return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        mobileText(){
            if(this.form.customer && this.form.customer.mobile){
                const mobile = this.form.customer.mobile;
                return mobile.substring(0, 3) + '-' + mobile.substring(3, 10);
            }else{
                return '';
            }
        }
    },
    async mounted(){
        if(null == this.form.ordering.shippingFee || '0' == this.form.ordering.shippingFee){
            const body = {
                location1: this.form.ordering.address1Start.location,
                location2: this.form.ordering.address1Destination.location,
                weight: this.form.ordering.weight
            };
            const response = await axios.post(process.env.VUE_APP_BASE_URL + '/jobs/calculate-shipping', body);
            this.form.ordering.shippingFee = response.data.price;
        }
    },
    watch: {
        'form.ordering.shippingFee'(){
            console.log('test');
        }
    },
    components: {
        AlertSuccess, AlertError
    }
};
</script>
<template>
    <v-dialog
    v-model="dialog"
    persistent
    max-width="680"
    >
        <v-card dark class="pa-3" align="center">
            <v-card-text>
                <p class="text-body-1 font-weight-bold mb-0 mt-3 yellow--text text--darken-3">เพิ่มที่อยู่</p>
                <v-form ref="mapForm" v-model="isValid">
                <v-autocomplete
                    v-model="address1"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    label="แขวง/ตำบล, เขต/อำเภอ, จังหวัด, รหัสไปรษณีย์"
                    :rules="[ v => checkAllowAddress(v, channel) || 'อยู่นอกพื้นที่ให้บริการ']"
                ></v-autocomplete>
                <v-text-field
                    v-model="address2"
                    color="yellow darken-3"
                    label="รายละเอียดที่อยู่ (หลังจากกรอกเสร็จกด Enter เพื่อปักหมุด)"
                    :rules="[v => v != null && v.length > 5 || 'โปรดใส่รายละเอียด']"
                    @keydown.enter="updateMarkerPosition"
                ></v-text-field>
                </v-form>
                <v-alert border="left" class="mb-0" icon="mdi-fire" text dense color="warning" dismissible>
                     ปักหมุดตำแหน่งที่ใกล้เคียงที่สุด เพื่อสามารถจัดส่งสินค้าได้อย่างราบรื่น
                 </v-alert>
                <GmapMap
                    :center="mapCenter"
                    :zoom="15"
                    :options="mapOptions"
                    map-type-id="terrain"
                    style="width: 100%; height: 300px"
                    >
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="true"
                        @drag="updatedPlace"
                        @click="mapCenter=m.position"
                    />
                    </GmapMap>
            </v-card-text>
            <v-card-actions align="center">
            <v-btn
                color="yellow darken-3"
                outlined
                @click="close"
                class="px-10 ml-auto mr-3"
            >
                ยกเลิก
            </v-btn>
            <v-btn
                color="yellow darken-3"
                @click="confirm"
                class="px-10 mr-auto"
            >
                <label class="black--text">ยืนยัน</label>
            </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="apiLoading"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="yellow darken-3"
                dark
            >
                <v-card-text class="black--text text-center">
                กำลังค้นหาสถานที่จากข้อมูลที่กรอก
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
/**
 * Example JSON 
 *  {
 *    "district": "คลองท่อมเหนือ",
 *    "amphoe": "คลองท่อม",
 *    "province": "กระบี่",
 *    "zipcode": 81120,
 *    "district_code": 810402,
 *    "amphoe_code": 8104,
 *    "province_code": 81
 *   },
 */
import { filterdAddress } from '../models/address';
import axios from 'axios';
import { checkAllowAddress } from '../utils/text-mapping';

export default {
    data: () => ({
        dialog: false,
        id: null,
        address1: null,
        address2: null,
        myPlace: null,
        items: [],
        isLoading: false,
        apiLoading: false,
        search: null,
        markers: [],
        mapOptions: {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUI: true
        },
        mapCenter: { lat:13.7198746, lng:100.6083957 },
        isValid: false,
        channel: 0,
        checkAllowAddress
    }),
    props: {
        
    },
    methods: {
        show(addressDetails, channel = 1){
            this.channel = channel;
            this.id = addressDetails.id;
            this.address1 = addressDetails.address1;
            this.address2 = addressDetails.address2;
            this.dialog = true;
            if(addressDetails.location && 0 === Object.keys(addressDetails.location).length && Object.getPrototypeOf(addressDetails.location) === Object.prototype){
                this.setCurrentPosition();
            }else{
                this.markers = [ { position: addressDetails.location }];
                this.mapCenter = addressDetails.location;
            }
            // fix bug. if items not have value will not show anything
            if(addressDetails.address1 && 0 == this.items.filter(i => i == addressDetails.address1).length){
                this.items.push(addressDetails.address1);
            }
        },
        confirm(){
            this.$refs.mapForm.validate();
            if(this.isValid){
                const addressDetails = {
                    id: this.id,
                    address1: this.address1,
                    address2: this.address2,
                    location: this.myPlace,
                    text: `${this.address2} ${this.address1}`
                };
                this.$emit('confirm', addressDetails);
                this.close();
            }
        },
        close(){
            this.id = null;
            this.address1 = null;
            this.address2 = null;
            this.myPlace = null;
            this.setCurrentPosition();
            this.$refs.mapForm.resetValidation();
            this.dialog = false;
        },
        getAddress(value){
            this.isLoading = true;
            filterdAddress({ 
                district: value,
                amphoe: value,
                province: value,
                zipcode: value
            })
            .then(result => {
                this.items = result;
                this.isLoading = false;
            })
            .catch(error => {
                console.log(error);
            });
        },
        setCurrentPosition(){
            console.log(this.mapCenter);
            navigator.geolocation.getCurrentPosition(position => {
            this.mapCenter = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            this.markers = [ 
                { position: this.mapCenter } 
            ];
        });
        },
        updatedPlace(value){
            this.myPlace = { lat: value.latLng.lat(), lng: value.latLng.lng() };
        },
        async updateMarkerPosition(){
            if(! (this.address1 || this.address2)){
                return;
            }
            this.apiLoading = true;
            const textQuery = `${this.address2} ${this.address1}`;
            try{
                const response = await axios.post(process.env.VUE_APP_BASE_URL + '/services/place', { textQuery: textQuery });
                if(response.data.results.length > 0){
                    const result = response.data.results[0];
                    const position = result.geometry.location;
                    this.markers = [ { position: position }];
                    this.mapCenter = position;
                }else{
                    // not found check only address 1 scope
                    const response2 = await axios.post(process.env.VUE_APP_BASE_URL + '/services/place', { textQuery: this.address1 });
                    if(0 == response2.data.results.length || '' == this.address1 || null == this.address1){
                        console.log('not found location');
                        return '';
                    }
                    const result = response2.data.results[0];
                    const position = result.geometry.location;
                    this.markers = [ { position: position }];
                    this.mapCenter = position;
                }
            }catch(error){
                console.log(error);
            }finally{
                this.apiLoading = false; 
            }
        }
    },
    mounted(){

    },
    watch: {
        search (val) {
            val && val !== this.address1 && this.getAddress(val);
        },
        markers: {
            handler(value){
                this.myPlace = value[0].position;
            },
            deep: true
        },
        mapCenter(value){
            if(null == value){
                this.mapCenter = { lat:13.7198746, lng:100.6083957 }; // Protect first *** Invalid prop: type check failed for prop "center". Expected Object, got Null 
                this.setCurrentPosition();
            }
        }
    }
};
</script>

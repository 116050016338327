const getPaymentText = (value) => {
    if ('1' == value) {
        return 'เก็บเงินปลายทาง';
    } else if ('2' == value) {
        return 'โอนเงินผ่านธนาคาร หรือ Mobile Banking';
    } else {
        return 'เครดิต';
    }
};

export { getPaymentText };